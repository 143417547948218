.MuiTablePagination-actions {
  display: none;
}
.css-levciy-MuiTablePagination-displayedRows {
  display: none;
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

::-webkit-scrollbar-thumb {
  background: #108fe8; /* Scrollbar color */
  border-radius: 6px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}
